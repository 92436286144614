<template>
    <div class="top-issues mb-4">
        <div class="section-header font-weight-bold">
            <span>{{ title }}</span>
        </div>
        <div class="table-search">
            <cv-text-input
                label="Search"
                class="table-search--search"
                @input="onInput"
                :value="searchText"
            ></cv-text-input>
            <cv-dropdown
                @change="regionChange"
                :hide-selected="true"  
                :value="regionFilter"
                :up="true"
                label="Region Filter"
            >
                <cv-dropdown-item value="all"><span>All</span></cv-dropdown-item>
                <cv-dropdown-item value="na"><span>North America</span></cv-dropdown-item>
                <cv-dropdown-item value="eu">Europe</cv-dropdown-item>
                <cv-dropdown-item value="as">Asia</cv-dropdown-item>
                <cv-dropdown-item value="oc">Oceania</cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
                @change="platformChange"
                :hide-selected="true"  
                :value="platformFilter"
                :up="true"
                label="Platform Filter"
            >
                <cv-dropdown-item value="all"><span>All</span></cv-dropdown-item>
                <cv-dropdown-item value="xbl"><span>Xbox Live</span></cv-dropdown-item>
                <cv-dropdown-item value="psn">PSN</cv-dropdown-item>
                <cv-dropdown-item value="crossplay">Crossplay</cv-dropdown-item>
            </cv-dropdown>
            <p class="table-search--update"><strong>Last updated at: </strong><em>{{ this.serverStatus.last_updated }}</em></p>
        </div>   
        <cv-data-table-skeleton
            v-if="isLoading"
            :columns="5"
            :rows="10"
            helper-text="Loading Servers...">
        </cv-data-table-skeleton>
        <div v-else class="server-status--tableContainer">
            <cv-data-table
                :columns="columns"
                @pagination="actionOnPagination"
                :pagination="pagination"
                ref="table"
                :staticWidth="false"
                :autoWidth="false"
            >
                <template slot="data">
                    <cv-data-table-row
                        v-for="server in filteredData"
                        :key="`${server.server_id}`"
                        :value="`${server.server_id}`"
                    >
                        <cv-data-table-cell class="table-data--cell" style="">
                            <div v-if="server.stable" class="status-circle status-circle--running"></div>
                            <div v-if="!server.stable" class="status-circle status-circle--unstable"></div>
                        </cv-data-table-cell>

                        <cv-data-table-cell>
                            <h2 class="server--title">{{ server.name }}</h2>
                        </cv-data-table-cell>
                        <cv-data-table-cell>
                            <h3 class="server--title">{{ convertAPIString(server.region) }}</h3>
                        </cv-data-table-cell>
                        <cv-data-table-cell>
                            <h3 class="server--title">{{ convertAPIString(server.platform) }}</h3>
                        </cv-data-table-cell>
                        <cv-data-table-cell style="width: 120px">
                            <div v-if="server.stable" class="server--title">
                                <cv-tag kind="green" label="Stable"> </cv-tag>
                            </div>
                            <div v-if="!server.stable" class="server--title">
                                <cv-tag kind="red" label="Unstable"> </cv-tag>
                            </div>
                        </cv-data-table-cell>
                    </cv-data-table-row>
                </template>
            </cv-data-table>
        </div>
        <div class="mobile-pagination">
            <button class="mobile-pagination--button" @click="actionMobilePagination('left')"><span class="chevron left"></span>Prev</button>
            <p>Page {{ page }} of {{ maxPagesMobile }}</p>
            <button class="mobile-pagination--button" @click="actionMobilePagination('right')">Next <span class="chevron right"></span></button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    name: "Status",
    data: () => ({
        columns: ["", "Name", "Region", "Platform", "Status"],
        searchText: "",
        isLoading: false,
        isError: false,
        pageStart: 0,
        pageLength: 5,
        itemsLength: 0,
        serverFetch: null,
        regionFilter: 'all',
        platformFilter: 'all',
        maxPagesMobile: 0,
        page: 1,
        pagination: {
            numberOfItems: 0,
            pageSizes: [10, 15, 20, 25, 50]
        },
    }),
    computed: {
        ...mapGetters(['serverStatus']),
        filteredData() {
            let filteredData = this.serverStatus.servers;

            // Filter by region first.
            if (this.regionFilter && this.regionFilter !== 'all') {
                filteredData = filteredData.filter(server => {
                    return server.region === this.regionFilter;
                });
            }

            // Filter by platform second.
            if (this.platformFilter && this.platformFilter !== 'all') {
                filteredData = filteredData.filter(server => {
                    return server.platform === this.platformFilter;
                });
            }

            // Filter by search text finally.
            if (this.searchText) {
                filteredData = filteredData.filter((server) => {

                return (
                    server.name
                    .toLowerCase()
                    .indexOf(this.searchText.toLowerCase()) !== -1);
                });
            } 
            
            this.itemsLength = filteredData.length;
            this.pagination.numberOfItems = filteredData.length;
            this.maxPagesMobile = Math.ceil(this.itemsLength / 10);

            if (this.pageStart) {
                return filteredData.slice(
                    this.pageStart - 1,
                    this.pageStart - 1 + this.pageLength
                );
            } else {
                return filteredData;
            }
        },
    },
    props: [ 'title' ],
    async created() {        
        this.isLoading = true;
        
        const result = await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API_ROOT}/servers/status`,
        });

        if (result.status === 200) {
            this.isLoading = false;
            this.isError = false;
            this.$store.commit('updateServerStatus', result.data);
            const fiveMinutes = 5 * 60000;

            // Set the job going to refresh the list every 4 minutes the component is mounted.
            this.serverFetch = setInterval(() => {
                this.refreshList(); 
            }, fiveMinutes);

            return; 
        }

        this.isLoading = false;
        this.isError = true;
    },
    beforeDestroy() {
        if (this.serverFetch) {
            clearInterval(this.serverFetch);
        }
    },
    methods: {
        actionOnPagination(ev) {
            this.pageLength = ev.length;
            this.pageStart = ev.start;
            this.page = ev.page;
        },
        // Our own implementation of mobile paginataion
        actionMobilePagination(type) {
            if (type === 'left' && this.page !== 1) {
                this.page -= 1;
                this.pageStart -= 10;
                this.pageLength = 10;
            }

            if (type === 'right' && this.page !== this.maxPagesMobile) {
                this.page += 1;
                this.pageStart += 10;
                this.pageLength = 10;
            }
        },
        onInput(val) {
            this.searchText = val;
        },
        async refreshList() {
            const result = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_API_ROOT}/servers/status`,
            });
            
            if (result.status === 200) {
                this.isError = false;
                this.isLoading = false;
                this.$store.commit('updateServerStatus', result.data);
            }
        },
        navigateToBugs() {
            this.$router.push('/');
        },
        regionChange(value) {
            this.regionFilter = value;
        },
        platformChange(value) {
            this.platformFilter = value;
        },
        convertAPIString(string) {
            // Return the full string of regions instead of codes.
            switch(string) {
                case 'na': 
                    return 'North America';
                case 'as':
                    return 'Asia';
                case 'eu':
                    return 'Europe';
                case 'oc':
                    return 'Oceania';
                case 'psn':
                    return 'PSN';
                case 'xbl':
                    return 'Xbox Live';
                case 'crossplay':
                    return 'Crossplay';
                default:
                    return '';
            };
        }
    }    
};
</script>

<style lang="scss">
    @import "../assets/css/variables.scss";
    

    .server--title {
        font-family: roboto-condensed, Roboto Condensed, sans-serif;
        font-weight: 800;
        font-size: 1.7em;

        @media screen and (max-width: 992px) {
            font-size: 1em;
        }
    }
    .chevron::before {
	    border-style: solid;
	    border-width: 0.25em 0.25em 0 0;
	    content: '';
	    display: inline-block;
	    height: 0.45em;
	    left: 0.15em;
	    position: relative;
	    top: 0.15em;
	    transform: rotate(-45deg);
	    vertical-align: top;
	    width: 0.45em;
    }
    .chevron.right:before {
        margin-top: .1rem;
	    transform: rotate(45deg);
    }
    .chevron.left:before {
        margin-top: .1rem;
        margin-right: 4px;
	    transform: rotate(-135deg);
    }
    // Mobile pagination doesn't exist in cv-data-table, so add our own.
    .mobile-pagination {
        display: none;
        
        @media screen and (max-width: 768px) {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 1rem;
        }

        p {
            padding: 0 1rem;
            font-size: 12px;
        }
    }
    .mobile-pagination--button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: white;
        background-color: $danger;
        border-color: $danger;
        padding: .5rem;
        width: 100px;
        text-align: center;
    }
    // Get rid of out of the box pagination on mobile.
    .cv-pagination {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    .disabled {
        pointer-events: none;
    }
    .table-data--cell {
        width: 75px;
        margin: 0 auto;
    }
    .status-circle {
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
    }
    .status-circle--running {
        background-color: $success;
    }
    .status-circle--unstable {
        background-color: $danger;
    }
    .table-search {
        height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        div {
            margin-right: 2rem;
        }

        .bx--list-box__menu-icon {
            margin: 0;
        }



        @media screen and (max-width: 992px) {
            flex-direction: column;

            div {
                margin-right: 0;
                margin-bottom: 1rem;
                width: 100%;
            }
        }
    }
    .table-search--search {
        @media screen and (max-width: 992px) {
            margin-bottom: 1rem;
        }
    }
    .statusPage-loading {
        p {
            font-size: 1rem;
        }
        .bx--inline-loading {
            justify-content: center;
        }
    }
    .statusPage-error {
        display: flex;
        justify-content: center;
        
        .bx--inline-notification__close-button {
            display: none;
        }
    }
    
    .server-status--tableContainer {
        .bx--data-table-container {
            overflow-x: auto;
            white-space: nowrap;
        }
    }
</style>