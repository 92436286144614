<template>
    <main>
        <div class="button-group--row">
            <ActionButtons buttonText="REPORT A BUG" route="/report-bug" class="my-3" />
            <ActionButtons buttonText="BUGS" route="/" class="my-3" />
        </div>

        <div>
            <StatusTable title="Server Status" />
        </div>
    </main>
</template>

<script>
  import ActionButtons from "@/components/ActionButtons.vue";
  import StatusTable from "@/components/StatusTable.vue";
  export default {
    name: "Status",
    components: {
      ActionButtons,
      StatusTable,
    },
  };
</script>

<style lang="scss">
  .button-group--row {
    display: flex;
    @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
    
    .action-buttons {
      margin-right: 1rem;

      @media screen and (max-width: 992px) {
        width: 100%;

        a {
          width: 100%;
        }
      }
    }
  }
</style>